<template>
  <div>
    <b-card no-body>
      <h4 class="card-title p-2 mb-0">Add Employee</h4>
      <b-form autocomplete="off" id="shift-employee" ref="shiftEmployee" class="p-2">
        <b-form-group label="Employee" label-for="employee">
          <v-select
            v-model="data_local.employee_ids"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="employeeListOptions"
            :clearable="false"
            :reduce="(val) => val.code"
            input-id="employee"
            name="employee"
            placeholder="Select Employee"
            multiple
          />
        </b-form-group>
        <b-button
          id="shifts-form-submit"
          type="button"
          variant="primary"
          @click="formSubmitted"
        >
          Save
        </b-button>
      </b-form>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <h4 class="card-title p-2 mb-0">Shift Employees</h4>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              v-if="selectedShifts.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search By Name"
                />
              </b-input-group>
            </div>
            <b-button
              v-else-if="false"
              class="float-right"
              variant="danger"
              :disabled="shiftsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ shiftsDeleteCount }} Shift</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedShifts"
        name="selectedShifts"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchShiftList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('employee_edit')"
                :to="{
                  name: 'edit-employee',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
              <span v-else>
                {{ data.item.name }}
              </span>
            </b-media>
          </template>

          <template #cell(session_1_start_from)="data">
            <b-media vertical-align="center">
              {{ data.item.session_1_start_from | formatTime }}
            </b-media>
          </template>

          <template #cell(session_2_end_at)="data">
            <b-media vertical-align="center">
              {{ data.item.session_2_end_at | formatTime }}
            </b-media>
          </template>

          <!--Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status == "1" ? "Active" : "In Active" }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              variant="info"
              class=""
              :to="{
                name: 'edit-employee',
                params: { id: data.item.hashid },
              }"
              v-if="$can('employee_edit')"
            >
              <feather-icon icon="EditIcon" size="13" class="mr-50" />
              <span>Edit</span>
            </b-button>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-if="selectedShifts.length == 0"
              v-model="currentPage"
              :total-rows="totalShiftList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="false"
              class="float-right"
              variant="danger"
              :disabled="shiftsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ shiftsDeleteCount }} Shift</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import shiftStoreModule from "../shiftStoreModule";
import useShiftEmployeesList from "./useShiftEmployeesList";

import { useRouter, useRoute } from "vue-router";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    id: {
      type: String,
    },
  },
  setup(props) {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-shifts";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedShifts = ref([]);
    const shiftsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(REFERRAL_APP_STORE_MODULE_NAME, shiftStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchShiftList,
      tableColumns,
      perPage,
      currentPage,
      totalShiftList,
      shiftsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      filterByShiftUrgencyStatus,
      filterByShiftType,
      resolveUserStatusVariant,
      shiftStatusOption,
      countryFilter,
    } = useShiftEmployeesList(props.id);

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedShifts.value = [];
      if (val) {
        shiftsMetaData.value.forEach((shift) => {
          selectedShifts.value.push(shift.hashid);
        });
      }
    });

    watch(selectedShifts, (val) => {
      shiftsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchShiftList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalShiftList,
      // Extra Filters
      statusOptions,
      statusFilter,
      filterByShiftUrgencyStatus,
      filterByShiftType,
      selectedShifts,
      shiftsDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      shiftStatusOption,
      countryFilter,
    };
  },
  data() {
    return {
      employeeListOptions: [],
      data_local: {
        shift_id: this.$route.params.id,
        employee_ids: [],
      },
    };
  },
  created() {
    //   console.log(this.$route.params.id);
  },
  mounted() {
    this.fetchEmployeeList();
  },
  methods: {
    fetchEmployeeList() {
      const self = this;
      this.$store
        .dispatch("app-shifts/fetchEmployeeList", self.data_local)
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("shift-employee"));

      this.isBusy = true;
      this.$store
        .dispatch("app-shifts/addEmployee", this.data_local)
        .then((res) => {
          // Add this shifts to NHR
          this.isBusy = false;
          this.fetchEmployeeList();
          self.data_local.employee_ids = [];
          self.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Shift Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Shift Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.shiftEmployee.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `This will remove the member from the shift.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel please!",
        customClass: {
          confirmButton: "btn btn-primary min-w-200",
          cancelButton: "btn btn-outline-danger ml-1 min-w-200",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedShifts.length} Shift, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel please!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-shifts/removeMultipleShifts", this.selectedShifts)
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedShifts = [];
              if (res.data.success) {
                this.refetchData();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted Successfully.",
                    icon: "BellIcon",
                    variant: "success",
                    text: res.data.message,
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Shift Deletion failed.",
                    icon: "BellIcon",
                    variant: "danger",
                    text: res.data.message,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchShiftsData() {
      console.log("Added Shift");
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-shifts/removeUser", id)
        .then((res) => {
          if (res.status == "204") {
            this.fetchEmployeeList();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Shift Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Shift Deleted Successfully.",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Shift Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Shift Deletion failed.",
              icon: "BellIcon",
              variant: "danger",
              // text: err.data.message,
            },
          });
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
