import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useRouter, useRoute } from 'vue-router'

export default function ShiftList(id) {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        // { key: "select", sortable: false, label: "Select" },
        { key: "name", sortable: true, label: "Name" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalShiftList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const countryFilter = ref(null);
    const shiftsMetaData = ref([]);
    const shiftStatusOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalShiftList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [currentPage, perPage, searchQuery, statusFilter, countryFilter],
        () => {
            refetchData();
        }
    );
    const fetchShiftList = (ctx, callback) => {
        let formData = {};
        formData.url = "shifts/employees/" + id;

        formData.params = {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,
            country: countryFilter.value
        };
        store
            .dispatch("app/get", formData)
            .then(response => {
                const shift = response.data.data;
                const { total } = response.data;
                shiftStatusOption.value = response.data.status;


                callback(shift);
                totalShiftList.value = total;

            })
            .catch(error => {
                console.error(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching shifts",
                        icon: "AlertTriangleIcon",
                        variant: "danger"
                    }
                });
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == false) return "danger";
        if (status == true) return "success";
        return "primary";
    };

    return {
        fetchShiftList,
        tableColumns,
        perPage,
        currentPage,
        totalShiftList,
        shiftsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        shiftStatusOption,
        statusFilter,
        countryFilter
    };
}
